import styles from "./LeftSideBar.module.css";

function LeftSideBar() {
  return (
    <div className={styles.edge}>
      <div className={styles.sideBar}>
        <div className={styles.content}>
          <h3>최근 방문</h3>
          <p>삼성전자 커뮤니티</p>
          <p>해외 주식 커뮤니티</p>
          <p>테슬라 커뮤니티</p>
          <p>엔비디아 커뮤니티</p>
          <p>국내 주식 커뮤니티</p>
        </div>
        <div className={styles.content}>
          <h3>인기 게시물🔥</h3>
          <p>
            엔비디아 차세대 차량용 자율주행 도메인 제어 칩 엔비디아 Thor, 허페이
            공장에 도입
          </p>
          <p>트럼프, 일론머스크 인터뷰 중</p>
          <p>닉 티미라오스</p>
          <p>파월 의장, 금리 인하 시사 하지만 언제인지는 밝히지 않음</p>
          <p>미국 증시 및 기업 이슈</p>
        </div>
        <div className={styles.content}>
          <h3>인기 커뮤니티</h3>
          <p>삼성전자 커뮤니티</p>
          <p>해외 주식 커뮤니티</p>
          <p>테슬라 커뮤니티</p>
          <p>엔비디아 커뮤니티</p>
          <p>국내 주식 커뮤니티</p>
        </div>
        <div className={styles.content}>
          <h3>사이트 정보</h3>
          <p>nestock 소개</p>
          <p>멤버쉽 소개</p>
          <p>사용자 이용 약관</p>
          <p>개인정보 처리방침</p>
          <p>문의하기</p>
        </div>
      </div>
    </div>
  );
}

export default LeftSideBar;
