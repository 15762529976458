import styles from "./RightSideBar.module.css";

function RightSideBar() {
  return <></>;
}

// function RightSideBar() {
//   return (
//     <div className={styles.edge}>
//       <div className={styles.sideBar}>
//         <h2>📈인기 급상승 커뮤니티📈</h2>
//         <div className={styles.rankBox}>
//           <p>국내주식 커뮤니티</p>
//           <p>해외주식 커뮤니티</p>
//           <p>부동산 커뮤니티</p>
//           <p>가상화폐 커뮤니티</p>
//         </div>
//       </div>
//     </div>
//   );
// }

export default RightSideBar;
