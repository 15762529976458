import { getPosts } from "../api";
import ShowPost from "./ShowPost";
import styles from "./PostList.module.css";

function PostList({ isCommunity = false, keyword = "" }) {
  const posts = getPosts(isCommunity, keyword);

  return (
    <div className={styles.box}>
      {posts.map((post) => (
        <div key={post.id} className={styles.innerBox}>
          <div className={styles.post}>
            <ShowPost value={post} isCommunity={isCommunity} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default PostList;
