// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeftSideBar_edge__OXkfd {
  padding-right: 1px;
  border-right: var(--border-line);
  width: 16vw;
  height: calc(100vh - 74px);
  background-color: rgb(247, 247, 247);
  position: fixed;
  top: 74px;
  overflow-y: scroll;
}

.LeftSideBar_edge__OXkfd::-webkit-scrollbar {
  width: 10px;
}

.LeftSideBar_edge__OXkfd::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 12px 12px 12px 12px;
}

.LeftSideBar_edge__OXkfd:hover::-webkit-scrollbar-thumb {
  background: rgb(220, 220, 220);
}

.LeftSideBar_sideBar__63tWM {
  padding: 0 15px;
}

.LeftSideBar_sideBar__63tWM p {
  color: rgb(48, 48, 48);
}

.LeftSideBar_sideBar__63tWM > div:not(:last-of-type) {
  border-bottom: var(--border-line);
}

.LeftSideBar_content__4h04l {
  width: 11vw;
  padding: 0 10px;
}

.LeftSideBar_content__4h04l > p {
  width: 11vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/LeftSideBar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gCAAgC;EAChC,WAAW;EACX,0BAA0B;EAC1B,oCAAoC;EACpC,eAAe;EACf,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,kCAAkC;AACpC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".edge {\n  padding-right: 1px;\n  border-right: var(--border-line);\n  width: 16vw;\n  height: calc(100vh - 74px);\n  background-color: rgb(247, 247, 247);\n  position: fixed;\n  top: 74px;\n  overflow-y: scroll;\n}\n\n.edge::-webkit-scrollbar {\n  width: 10px;\n}\n\n.edge::-webkit-scrollbar-thumb {\n  background: transparent;\n  border-radius: 12px 12px 12px 12px;\n}\n\n.edge:hover::-webkit-scrollbar-thumb {\n  background: rgb(220, 220, 220);\n}\n\n.sideBar {\n  padding: 0 15px;\n}\n\n.sideBar p {\n  color: rgb(48, 48, 48);\n}\n\n.sideBar > div:not(:last-of-type) {\n  border-bottom: var(--border-line);\n}\n\n.content {\n  width: 11vw;\n  padding: 0 10px;\n}\n\n.content > p {\n  width: 11vw;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edge": `LeftSideBar_edge__OXkfd`,
	"sideBar": `LeftSideBar_sideBar__63tWM`,
	"content": `LeftSideBar_content__4h04l`
};
export default ___CSS_LOADER_EXPORT___;
