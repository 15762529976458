// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostPage_comment__n1DsY:not(:last-of-type) {
  border-bottom: var(--border-box);
}
`, "",{"version":3,"sources":["webpack://./src/pages/PostPage.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC","sourcesContent":[".comment:not(:last-of-type) {\n  border-bottom: var(--border-box);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment": `PostPage_comment__n1DsY`
};
export default ___CSS_LOADER_EXPORT___;
