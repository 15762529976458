import styles from "../components/SearchBar.module.css";
import searchIcon from "../assets/search.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetSearchParams } from "../contexts/SearchContext";
import { useEffect, useState } from "react";
import AutoComplete from "./AutoComplete";

function SearchBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const setSearch = useSetSearchParams();
  const [keyword, setKeyword] = useState("");
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [className, setClassName] = useState([
    styles.blurredInput,
    styles.blurredButton,
  ]);

  const getSearchHistory = () => {
    const historyData = localStorage.getItem("searchHistory");
    return historyData ? JSON.parse(historyData) : [];
  };

  const [history, setHistory] = useState(getSearchHistory);

  function editSearchHistory(method, keyword) {
    setHistory((prevHistory) => {
      console.log(keyword);
      console.log(prevHistory);
      const filtered = prevHistory.filter((record) => record !== keyword);
      console.log(filtered);
      switch (method) {
        case "add":
          console.log("added");
          return filtered
            ? [keyword, ...filtered].splice(0, 20)
            : [keyword, ...prevHistory].splice(0, 20);
        case "delete":
          console.log("deleted");
          return filtered;
        default:
          console.log("method 입력 오류");
      }
      return prevHistory;
    });
    console.log(history);
  }

  const handleKeywordChange = (e) => setKeyword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch((prevSearch) =>
      keyword
        ? {
            ...prevSearch,
            keyword,
          }
        : {}
    );
    document.getElementById("searchInput").blur();
    editSearchHistory("add", keyword);
    navigate("/nest");
  };

  const handleFocus = (e) => {
    e.preventDefault();
    setIsOnFocus(true);
    setClassName([styles.focusedInput, styles.focusedButton]);
  };

  const handleBlur = (e) => {
    e.preventDefault();
    setIsOnFocus(false);
    setClassName([styles.blurredInput, styles.blurredButton]);
  };

  useEffect(() => {
    setKeyword("");
    console.log(history);
  }, [location]);

  useEffect(() => {
    localStorage.setItem("searchHistory", JSON.stringify(history));
  }, [history]);

  return (
    <div className={styles.searchBar}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          id="searchInput"
          name="keyword"
          value={keyword}
          onChange={handleKeywordChange}
          placeholder="검색"
          autoComplete="off"
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={className[0]}
        ></input>
        <button type="submit" className={className[1]}>
          <img src={searchIcon} alt="검색" />
        </button>
      </form>
      {isOnFocus && (
        <AutoComplete
          keyword={keyword}
          aboutHistory={{ history, editSearchHistory }}
        />
      )}
    </div>
  );
}

export default SearchBar;
