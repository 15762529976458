// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RightSideBar_edge__j1N0v {
  width: 20vw;
  height: 30vw;
  border-radius: 25px;
  padding: 15px 15px;
  margin-top: 5vw;
  margin-left: 5vw;
  margin-right: 5vw;
  background-color: rgb(238, 241, 238);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.RightSideBar_sideBar__YfbYj {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.RightSideBar_sideBar__YfbYj * {
  text-align: center;
}

.RightSideBar_rankBox__XIY5V {
  height: calc(30vw - 90px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.RightSideBar_rankBox__XIY5V > p {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/RightSideBar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,oCAAoC;EACpC,wEAAwE;AAC1E;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,6BAA6B;AAC/B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".edge {\n  width: 20vw;\n  height: 30vw;\n  border-radius: 25px;\n  padding: 15px 15px;\n  margin-top: 5vw;\n  margin-left: 5vw;\n  margin-right: 5vw;\n  background-color: rgb(238, 241, 238);\n  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);\n}\n\n.sideBar {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.sideBar * {\n  text-align: center;\n}\n\n.rankBox {\n  height: calc(30vw - 90px);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n}\n\n.rankBox > p {\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edge": `RightSideBar_edge__j1N0v`,
	"sideBar": `RightSideBar_sideBar__YfbYj`,
	"rankBox": `RightSideBar_rankBox__XIY5V`
};
export default ___CSS_LOADER_EXPORT___;
