import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import MainPage from "./pages/MainPage";
import PostListPage from "./pages/PostListPage";
import CommunityPage from "./pages/CommunityPage";
import PostPage from "./pages/PostPage";
import NotFoundPage from "./pages/NotFoundPage";

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<MainPage />} />
          <Route path="nest">
            <Route index element={<PostListPage />} />
            <Route path=":communitySlug">
              <Route index element={<CommunityPage />} />
              <Route path="post">
                <Route index element={<NotFoundPage />} />
                <Route path=":postId" element={<PostPage />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
