import { useLocation } from "react-router-dom";
import PostList from "../components/PostList";
import ShowPageProfile from "../components/ShowPageProfile";
import { getCommunityInformation } from "../api";
import Warn from "../components/Warn";

function CommunityPage() {
  const location = useLocation();
  const communitySlug = location.pathname.split("/")[2];
  const { title } = getCommunityInformation(communitySlug);
  if (title === "error") {
    return <Warn />;
  }

  return (
    <>
      <ShowPageProfile title={title} />
      <PostList isCommunity={true} keyword={communitySlug} />
    </>
  );
}

export default CommunityPage;
