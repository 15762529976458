import PostList from "../components/PostList";

function MainPage() {
  return (
    <>
      <PostList isCommunity={false} />
    </>
  );
}

export default MainPage;
