// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFoundPage_container__YNLty {
  width: 64vw;
}

.NotFoundPage_link__kZuZL {
  margin: 30px auto;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".container {\n  width: 64vw;\n}\n\n.link {\n  margin: 30px auto;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotFoundPage_container__YNLty`,
	"link": `NotFoundPage_link__kZuZL`
};
export default ___CSS_LOADER_EXPORT___;
