import { useLocation } from "react-router-dom";
import ShowPost from "../components/ShowPost";
import { getPost } from "../api";
import styles from "./PostPage.module.css";

function ShowComment({ info }) {
  const { content, writer, createdAt } = info;
  return (
    <div className={styles.comment}>
      <p>{content}</p>
      <p>{writer}</p>
      <p>{createdAt}</p>
    </div>
  );
}

function PostPage() {
  const location = useLocation();
  const postId = location.pathname.split("/")[4];
  const post = getPost(postId);
  const comments = post.comment;
  return (
    <>
      <ShowPost isPostPage={true} value={post} />
      <h3>댓글 {comments.length}개</h3>
      {comments.map(({ id, content, writer, createdAt }) => (
        <ShowComment key={id} info={{ content, writer, createdAt }} />
      ))}
    </>
  );
}

export default PostPage;
