// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowPost_texts__2ZJo0 {
  width: 45vw;
}

.ShowPost_info__gTL7D {
  /* border-bottom: var(--border-line); */
}

.ShowPost_post__K413X {
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowPost.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".texts {\n  width: 45vw;\n}\n\n.info {\n  /* border-bottom: var(--border-line); */\n}\n\n.post {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"texts": `ShowPost_texts__2ZJo0`,
	"info": `ShowPost_info__gTL7D`,
	"post": `ShowPost_post__K413X`
};
export default ___CSS_LOADER_EXPORT___;
