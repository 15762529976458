// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMenu_userMenu__8\\+hhz {
  position: relative;
  padding-right: 75px;
}

.UserMenu_iconButton__TJd8U {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.UserMenu_userMenu__8\\+hhz,
.UserMenu_iconButton__TJd8U,
.UserMenu_iconButton__TJd8U img {
  width: 30px;
  height: 30px;
}

ul.UserMenu_popup__RQtRz {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #fff;
  position: absolute;
  top: calc(100% + 25px);
  right: -10px;
  border: 1px solid #ebebeb;
  box-shadow: var(--box-shadow);
  border-radius: 5px 0 5px 5px;
  overflow: hidden;
}

ul.UserMenu_popup__RQtRz li {
  padding: 15px 19px;
  font-family: var(--noto-sans);
}

ul.UserMenu_popup__RQtRz li:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

ul.UserMenu_popup__RQtRz li.UserMenu_disabled__Kaky3 {
  background-color: #fff;
  color: #c4c4c4;
  -webkit-user-select: none;
          user-select: none;
  cursor: default;
}

.UserMenu_userMenu__8\\+hhz ul.UserMenu_popup__RQtRz a:hover {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/UserMenu.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,UAAU;AACZ;;AAEA;;;EAGE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,yBAAyB;EACzB,6BAA6B;EAC7B,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,sBAAsB;EACtB,cAAc;EACd,yBAAiB;UAAjB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".userMenu {\n  position: relative;\n  padding-right: 75px;\n}\n\n.iconButton {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n}\n\n.userMenu,\n.iconButton,\n.iconButton img {\n  width: 30px;\n  height: 30px;\n}\n\nul.popup {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n  background-color: #fff;\n  position: absolute;\n  top: calc(100% + 25px);\n  right: -10px;\n  border: 1px solid #ebebeb;\n  box-shadow: var(--box-shadow);\n  border-radius: 5px 0 5px 5px;\n  overflow: hidden;\n}\n\nul.popup li {\n  padding: 15px 19px;\n  font-family: var(--noto-sans);\n}\n\nul.popup li:hover {\n  background-color: #f4f4f4;\n  cursor: pointer;\n}\n\nul.popup li.disabled {\n  background-color: #fff;\n  color: #c4c4c4;\n  user-select: none;\n  cursor: default;\n}\n\n.userMenu ul.popup a:hover {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userMenu": `UserMenu_userMenu__8+hhz`,
	"iconButton": `UserMenu_iconButton__TJd8U`,
	"popup": `UserMenu_popup__RQtRz`,
	"disabled": `UserMenu_disabled__Kaky3`
};
export default ___CSS_LOADER_EXPORT___;
