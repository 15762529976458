// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowPostProfile_profile__pVgo2 {
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ShowPostProfile_img__nP7SQ {
  height: 45px;
  border-radius: 23px;
}

.ShowPostProfile_avatar__OaP26 {
  padding-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowPostProfile.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".profile {\n  height: 45px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.img {\n  height: 45px;\n  border-radius: 23px;\n}\n\n.avatar {\n  padding-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": `ShowPostProfile_profile__pVgo2`,
	"img": `ShowPostProfile_img__nP7SQ`,
	"avatar": `ShowPostProfile_avatar__OaP26`
};
export default ___CSS_LOADER_EXPORT___;
