import styles from "./ShowPostProfile.module.css";
import DateText from "./DateText";
import pf from "../assets/logo.svg";
import { getCommunityTitle } from "../api";
import { Link } from "react-router-dom";

function ShowPostProfile({
  isPostPage = false,
  isCommunity = false,
  profile,
  time,
}) {
  const communityTitle = getCommunityTitle(profile.affiliation);

  if (isPostPage) {
  }
  return (
    <div className={styles.profile}>
      {isCommunity ? (
        <>
          <img src={pf} className={styles.img} alt="유저 이미지" />
          <p className={styles.avatar}>{profile.writer}</p>
          <DateText value={time} />
        </>
      ) : (
        <>
          <img src={pf} className={styles.img} alt="커뮤니티 이미지" />
          <Link to={`/nest/${profile.affiliation}`}>
            <p className={styles.avatar}>{communityTitle}</p>
          </Link>
          {isPostPage && <p>{profile.writer}</p>}
          <DateText value={time} />
        </>
      )}
    </div>
  );
}

export default ShowPostProfile;
